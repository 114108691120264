import GeerSelector from "src/components/GeerSelector";
import { useApexMenu } from "src/hooks/useApexMenu";
import styles from "./style.module.scss";

const ApexMenu: React.FunctionComponent = () => {
  const gameModes = useApexMenu((state) => state.gameModes);
  const selectGameMode = useApexMenu((state) => state.selectGameMode);

  return (
    <div className={styles.apexMenuContainer}>
      <GeerSelector
        gameModes={gameModes}
        onSelect={(mode) => selectGameMode(mode)}
      />
    </div>
  );
};

export default ApexMenu;
