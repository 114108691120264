import classnames from "classnames";
import styles from "./style.module.scss";
import { useEffect, useRef } from "react";
import BasicButton from "src/components/Base/Button/BasicButton";
import { RoomInfo, UpdateRoomInfo } from "src/utils/types/roomTypes";
import { covertDateString } from "src/utils/tools/common";
import selectBackGround from "src/assets/images/icon/ellipse25.svg";
import { useApexRoom } from "src/hooks/useApexRoom";

interface Props {
  webSocket: WebSocket | undefined;
  roomInfo: RoomInfo;
  isShow: boolean;
  onClick: () => void;
  onClose: () => void;
}

const OwnerScheduleModal: React.FunctionComponent<Props> = (props) => {
  const updateRoomInfo = useApexRoom((state) => state.updateRoomInfo);
  const eventDateTime = props.roomInfo.eventDateTime;
  const scheduleInfo = props.roomInfo.scheduleDateTime;
  const isScheduleConfirmed = eventDateTime !== 0;
  const eventDateString = covertDateString(eventDateTime);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return props.isShow ? (
    <div className={styles.modalWrapper}>
      <div className={styles.modalMask} />
      <div className={styles.scheduleContainer} ref={modalRef}>
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.closeButton
          )}
          onClick={() => props.onClose()}
        >
          close
        </span>
        <div className={styles.header}>開催スケジュール</div>
        <div className={styles.subHeader}>
          {props.roomInfo.publicFlag ? (
            <div>このルームのゲーム開催日は以下の日程となります</div>
          ) : (
            <>
              <div>
                参加者の回答状況を確認の上、開催日確定ボタンを押してください
              </div>
              <div>確定ボタンを押すと参加者の画面にも反映されます</div>
            </>
          )}
        </div>
        <div className={classnames(styles.contents, styles.ownerContents)}>
          {props.roomInfo.publicFlag ? (
            <div className={classnames(styles.publicSchedule)}>
              <div className={classnames(styles.date, styles.confirmedDate)}>
                {eventDateString}
              </div>
            </div>
          ) : (
            scheduleInfo?.map((info, key) => {
              const dateString = covertDateString(info.eventDateTime);
              const isConfirmed = info.eventDateTime === eventDateTime;

              return (
                <div
                  key={key}
                  className={classnames(styles.schedule, {
                    [styles.notConfirmedSchedule]:
                      !isConfirmed && isScheduleConfirmed,
                  })}
                >
                  {isConfirmed ? (
                    <div
                      className={styles.confirmSchedule}
                      onClick={() => {
                        if (isScheduleConfirmed) {
                          const newRoomInfo = props.roomInfo;
                          newRoomInfo.eventDateTime = 0;
                          updateRoomInfo(
                            props.webSocket,
                            newRoomInfo as UpdateRoomInfo
                          );
                        }
                      }}
                      hidden={props.roomInfo.publicFlag}
                    >
                      取り消す
                    </div>
                  ) : (
                    <div
                      className={styles.confirmSchedule}
                      onClick={() => {
                        const newRoomInfo = props.roomInfo;
                        newRoomInfo.eventDateTime = info.eventDateTime;
                        updateRoomInfo(
                          props.webSocket,
                          newRoomInfo as UpdateRoomInfo
                        );
                      }}
                    >
                      確定
                    </div>
                  )}
                  <div className={styles.date}>{dateString}</div>
                  <div className={styles.selectBlock}>
                    <div
                      className={classnames(styles.block, styles.ownerBlock)}
                    >
                      <img
                        className={styles.blockBackGround}
                        src={selectBackGround}
                        alt=""
                      />
                      <span className={classnames(styles.blockIcon)}>●</span>
                    </div>
                    <div className={styles.number}>{info.yes.length}人</div>
                  </div>
                  <div className={styles.selectBlock}>
                    <div
                      className={classnames(styles.block, styles.ownerBlock)}
                    >
                      <img
                        className={styles.blockBackGround}
                        src={selectBackGround}
                        alt=""
                      />
                      <span className={classnames(styles.blockIcon)}>▲</span>
                    </div>
                    <div className={styles.number}>{info.or.length}人</div>
                  </div>
                  <div className={styles.selectBlock}>
                    <div
                      className={classnames(styles.block, styles.ownerBlock)}
                    >
                      <img
                        className={styles.blockBackGround}
                        src={selectBackGround}
                        alt=""
                      />
                      <span className={classnames(styles.blockIcon)}>×</span>
                    </div>
                    <div className={styles.number}>{info.no.length}人</div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className={styles.editButton}>
          <BasicButton
            className={styles.editButton}
            text={"編集する"}
            visual={"secondary"}
            isDisabled={!props.roomInfo.publicFlag && isScheduleConfirmed}
            onClick={() => {
              props.onClick();
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OwnerScheduleModal;
