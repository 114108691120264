import { FC, ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isDisable: boolean;
  size: "tiny" | "small" | "large";
  onClick: () => void;
}

const AddRoomButton: FC<ButtonProps> = ({
  size = "large",
  isDisable = true,
  ...props
}) => {
  return (
    <button
      className={styles[size]}
      disabled={isDisable}
      onClick={props.onClick}
    >
      <span className={styles.materialSymbolsOutlined}>add</span>
    </button>
  );
};
export default AddRoomButton;
