import { FC, InputHTMLAttributes, useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

interface ChatTextBoxProps {
  text: string;
  sendMessage: () => void;
  changeMessage: (value: string) => void;
  className?: any;
}

const ChatTextBox: FC<
  InputHTMLAttributes<HTMLInputElement> & ChatTextBoxProps
> = ({ changeMessage, sendMessage, ...props }) => {
  const [composing, setComposing] = useState<boolean>(false);

  return (
    <div className={classnames(styles.inputTextContainer)}>
      <input
        className={classnames(styles.inputText, props.className)}
        onChange={(e) => {
          changeMessage(e.target.value);
        }}
        onCompositionStart={(e) => {
          setComposing(true);
        }}
        onCompositionEnd={(e) => {
          setComposing(false);
        }}
        onKeyDown={(event: any) => {
          if (event.key === "Enter" && !composing) sendMessage();
        }}
        type={"text"}
        value={props.text}
        {...props}
      />
      <span
        className={classnames("material-symbols-outlined", styles.send)}
        onClick={sendMessage}
      >
        send
      </span>
    </div>
  );
};

export default ChatTextBox;
