import classnames from "classnames";
import styles from "./style.module.scss";
import BasicButton from "src/components/Base/Button/BasicButton";
import { GameMode } from "src/utils/types/roomTypes";
import { useEffect, useRef } from "react";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useNavigate } from "react-router-dom";

interface Props {
  roomID: string;
  type: GameMode;
  isShow: boolean;
  onClose: () => void;
}

const DeleteWarnModal: React.FunctionComponent<Props> = (props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);
  const deleteApexRoom = useApexRoom((state) => state.deleteApexRoom);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div className={classnames(styles.modalWrapper)}>
      <div className={classnames(styles.modalMask)} />
      <div className={classnames(styles.warnModalContainer)}>
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.closeButton
          )}
          onClick={() => {
            props.onClose();
          }}
        >
          close
        </span>
        <div className={styles.header}>
          <div className={styles.title}>ルームの削除</div>
          <div className={styles.resultSubTitle}>
            ルームの削除に関して以下内容にご注意ください
          </div>
        </div>
        <div className={styles.warnDescription}>
          <span>この操作を行うと</span>
          <span>現在入室中のルームが完全に削除されます</span>
          <span>削除されたルームは復元できません</span>
          <span>本当に削除してもよろしいですか？</span>
        </div>
        <div className={styles.bottom}>
          <div
            className={styles.backToResult}
            onClick={() => {
              props.onClose();
            }}
          >
            キャンセル
          </div>
          <BasicButton
            isDisabled={false}
            visual={"third"}
            text={"削除"}
            onClick={() => {
              setLoadingMessage("Loading...");
              deleteApexRoom(props.roomID).then(() => {
                setLoadingMessage("");
                props.onClose();
                navigate({
                  pathname: "/customGame/" + props.type,
                });
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteWarnModal;
