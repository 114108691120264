import styles from "./style.module.scss";
import randomImg from "src/assets/json/menuRandomIMG.json";

function MenuBackGround() {
  const randomList = randomImg.loading;
  const randomImageNumber = Math.floor(Math.random() * 16);

  return (
    <div className={styles.menuBackGroundContainer}>
      <div className={styles.backGroundShadow} />
      <img
        className={styles.backGroundImage}
        src={randomList[randomImageNumber]}
        alt=""
      />
    </div>
  );
}

export default MenuBackGround;
