import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import MockNews from 'src/assets/json/mockArticles.json';

const FooterNews: React.FunctionComponent = () => {
  const news = MockNews.slice().reverse()[0];
  return (
    <div className={styles.footerNewsWrapper}>
      <div className={styles.footerNewsContainer}>
        <div className={styles.newsContainer}>
          <Link to={`/news/?type=${news.type}`}>
            <div className={styles.typeIcon}>
              <span>{news.type.toUpperCase()}</span>
            </div>
          </Link>
          <div className={styles.newsDate}>{news.date}</div>
          <Link to={`/news/${news.id}`}>
            <div className={styles.newsTitle}>{news.title}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default FooterNews;
