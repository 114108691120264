import styles from "./style.module.scss";
import arrowsDown from "src/assets/images/icon/arrowsDown.svg";
import arrowsUp from "src/assets/images/icon/arrowsUp.svg";
import { useState } from "react";
import cn from "classnames";
import BasicButton from "../Base/Button/BasicButton";
import { RoomInfo } from "src/utils/types/roomTypes";
import classnames from "classnames";
import { covertDateString, isEmpty } from "src/utils/tools/common";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import { useApexUser } from "src/hooks/useApexUser";

interface Props {
  roomInfo: RoomInfo;
  onClick?: () => void;
  byURL?: boolean;
  onClose?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  byRoom?: boolean;
}

const Card: React.FunctionComponent<Props> = (props) => {
  const [isShow, setIsShow] = useState(false);
  const [isOpen, setIsOpen] = useState(
    props.byURL || props.byRoom ? true : false
  );
  const eventDateTime = covertDateString(props.roomInfo.eventDateTime);
  const maps: any = apexGameInfo[props.roomInfo.type]?.maps;
  const mapName = maps[props.roomInfo.map]?.en;
  const loadImage = maps[props.roomInfo.map]?.loadImage;
  const userType = useApexUser((state) => state.userType);
  const isOwner = userType === "Owner";

  return (
    <div
      className={cn(styles.cardContainer, {
        [styles.showArrowsDown]: isShow && !isOpen,
        [styles.showDetail]: isOpen,
        [styles.byRoomPlayer]: props.byRoom && !isOwner,
        [styles.byRoomOwner]: props.byRoom && isOwner,
      })}
      onMouseEnter={() => setIsShow(true)}
      onMouseLeave={() => setIsShow(false)}
      onClick={(e) => {
        if (!props.byURL && !props.byRoom) {
          if ((e.target as Element).id === "joinButton") {
            return;
          }
          setIsOpen(!isOpen);
        }
      }}
    >
      {(props.byURL || props.byRoom) && (
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.closeButton
          )}
          onClick={props.onClose}
        >
          close
        </span>
      )}
      <div className={styles.backGround}>
        <div className={styles.backGroundShadow}></div>
        <img className={styles.backGroundImage} src={loadImage} alt="" />
      </div>
      <div className={styles.banner}>
        <div
          className={classnames(styles.title, {
            [styles.showDetailTitle]: isOpen,
          })}
        >
          {props.roomInfo.title}
        </div>
        <div className={styles.keywords}>
          {props.roomInfo.keywords
            ?.filter((keyword) => !isEmpty(keyword))
            .map((keyword, key) => {
              return (
                <span key={key} className={styles.keyword}>
                  #{keyword}
                </span>
              );
            })}
        </div>
        <div className={styles.ownerInfo}>
          <span className={styles.ownerName}>{props.roomInfo.ownerName}</span>
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.ownerIcon,
              styles.filledStyle
            )}
          >
            account_circle
          </span>
        </div>
      </div>
      <div className={styles.eventDateInfo}>
        <span className={styles.nameOutline}>
          <span className={styles.name}>開催日</span>
        </span>
        <span className={styles.data}>
          {props.roomInfo.eventDateTime === 0 ? "調整中" : eventDateTime}
        </span>
      </div>
      <div className={styles.mapInfo}>
        <span className={styles.nameOutline}>
          <span className={styles.name}>マップ</span>
        </span>
        <span className={styles.data}>{mapName}</span>
      </div>
      {isOpen && (
        <div className={styles.detail}>
          <div className={styles.description}>{props.roomInfo.description}</div>
          <hr className={styles.line} />
          <div className={styles.settings}>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>チャット範囲</span>
              <span
                className={classnames(
                  "material-symbols-outlined",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.lobbyChatFlag
                  ? "全員"
                  : "管理者のみ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>チームへの自動加入</span>
              <span
                className={classnames(
                  "material-symbols-outlined",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.selfAssignFlag ? "オン" : "オフ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>
                エイムアシストの優先設定
              </span>
              <span
                className={classnames(
                  "material-symbols-outlined",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.aimAssistFlag ? "オン" : "オフ"}
              </span>
            </div>

            <div className={styles.settingDetail}>
              <span className={styles.settingName}>匿名モード</span>
              <span
                className={classnames(
                  "material-symbols-outlined",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.anonymousModeFlag
                  ? "オン"
                  : "オフ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>
                ゲームモードバリエーション
              </span>
              <span
                className={classnames(
                  "material-symbols-outlined",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.gameModeFlag
                  ? "通常"
                  : "トーナメント"}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className={styles.membersInfo}>
        <span className={styles.numbersInfo}>
          {props.roomInfo.members.filter((member) => !isEmpty(member.teamName))
            .length +
            " / " +
            props.roomInfo.limitPlayerNumber}
        </span>
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.groups,
            styles.filledStyle
          )}
        >
          groups
        </span>
      </div>
      {isOpen && !props.byRoom && (
        <div className={styles.joinButton}>
          <BasicButton
            id="joinButton"
            text="入室する"
            visual={"secondary"}
            isDisabled={false}
            onClick={(e) => {
              e.preventDefault();
              props.onClick && props.onClick();
            }}
          />
        </div>
      )}
      {!props.byURL && !props.byRoom && (
        <>
          {isShow && !isOpen && (
            <img className={styles.arrowsDown} src={arrowsDown} alt="" />
          )}
          {isOpen && <img className={styles.arrowsUp} src={arrowsUp} alt="" />}
        </>
      )}
      {props.byRoom && isOwner && (
        <div className={styles.editButtons}>
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.editButton
            )}
            onClick={props.onEdit}
          >
            edit
          </span>
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.editButton
            )}
            onClick={props.onDelete}
          >
            delete
          </span>
        </div>
      )}
    </div>
  );
};

export default Card;
