import { useApexRoom } from "src/hooks/useApexRoom";
import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import BasicButton from "src/components/Base/Button/BasicButton";
import { isEmpty } from "src/utils/tools/common";
import { useApexUser } from "src/hooks/useApexUser";

interface Props {
  onClose: () => void;
}

interface KickoutPlayer {
  uuid: string;
  name: string;
}

const PlayerKickModal: React.FunctionComponent<Props> = (props) => {
  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";
  const roomInfo = useApexRoom((state) => state.roomInfo);
  const kickout = useApexRoom((state) => state.kickoutPlayers);
  const getRoomInfo = useApexRoom((state) => state.getRoomInfo);
  const uuid = useApexUser((state) => state.uuid);
  const members = roomInfo.members;
  const [kickoutPlayers, setKickoutPlayers] = useState<Array<KickoutPlayer>>(
    []
  );
  const [ifConfirm, setIfConfirm] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const sortedPlayers: any = {
    未割り当て: [] as Array<KickoutPlayer>,
  };

  members.forEach((member) => {
    const kickMemberData = {
      uuid: member.uuid,
      name: member.name,
    };
    if (member.uuid === uuid) {
      return;
    }
    if (isEmpty(member.teamName)) {
      sortedPlayers["未割り当て"].push(kickMemberData);
    } else {
      if (sortedPlayers[member.teamName]) {
        sortedPlayers[member.teamName].push(kickMemberData);
      }
      sortedPlayers[member.teamName] = new Array(kickMemberData);
    }
  });

  useEffect(() => {
    getRoomInfo(roomID);
  }, [getRoomInfo, roomID]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalMask} />
      {ifConfirm ? (
        <div className={styles.confirmContainer} ref={modalRef}>
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.closeButton
            )}
            onClick={() => props.onClose()}
          >
            close
          </span>
          <div className={styles.header}>確認</div>
          <div className={styles.subHeader}>
            オンライン状態が確認できないゲストや、ルームに対して悪質な行為を行うゲストを
            オーナーの任意でキックすることができます
          </div>
          <ul className={styles.kickoutContents}>
            {kickoutPlayers.map((kickoutPlayer) => {
              return <li>{kickoutPlayer.name}</li>;
            })}
          </ul>
          <BasicButton
            text="キックする"
            visual={"secondary"}
            isDisabled={false}
            onClick={() => {
              kickout(roomID, kickoutPlayers);
              setIfConfirm(false);
              setKickoutPlayers([]);
              props.onClose();
            }}
          />
          <div
            className={styles.cancel}
            onClick={() => {
              setIfConfirm(false);
            }}
          >
            キャンセル
          </div>
        </div>
      ) : (
        <div className={styles.kickoutContainer} ref={modalRef}>
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.closeButton
            )}
            onClick={() => props.onClose()}
          >
            close
          </span>
          <div className={styles.header}>プレイヤーキック</div>
          <div className={styles.subHeader}>
            以下のプレイヤーをルームからキックします。本当によろしいですか？
          </div>
          <div className={styles.contents}>
            {Object.entries(sortedPlayers).map(([key, value]) => {
              const styledValue = value as Array<KickoutPlayer>;

              return (
                <>
                  <div className={styles.unassigned}>{key}</div>
                  <div className={styles.playerCheck}>
                    {styledValue.map((member) => {
                      const checked = kickoutPlayers.some(
                        (kickoutPlayer) => kickoutPlayer.uuid === member.uuid
                      );

                      return (
                        <>
                          <span
                            className={classnames(
                              "material-symbols-outlined",
                              styles.checkBox
                            )}
                            onClick={() => {
                              if (checked) {
                                setKickoutPlayers(
                                  kickoutPlayers.filter(
                                    (kickoutPlayer) =>
                                      kickoutPlayer.uuid !== member.uuid
                                  )
                                );
                              } else {
                                setKickoutPlayers([
                                  ...kickoutPlayers,
                                  {
                                    uuid: member.uuid,
                                    name: member.name,
                                  },
                                ]);
                              }
                            }}
                          >
                            {checked ? "check_box" : "check_box_outline_blank"}
                          </span>
                          <span className={styles.playerName}>
                            {member.name}
                          </span>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
          <BasicButton
            text="確認する"
            visual={"secondary"}
            isDisabled={kickoutPlayers.length <= 0}
            onClick={() => {
              setIfConfirm(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PlayerKickModal;
