import { useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

interface Props {
  isRequired?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
}

const InputTextSmall: React.FunctionComponent<Props> = (props) => {
  const [unmasking, setUnmasking] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const isPassword = props.type === "password";
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.isRequired && setError(e.target.value.length === 0);
    props.onChange && props.onChange(e);
  };
  const inputText = (
    <>
      <input
        className={classnames(
          styles.inputText,
          isError && styles.error,
          props.className
        )}
        onChange={onChangeHandler}
        type={unmasking ? "text" : props.type}
        value={props.value}
      />
      {isError && <div className={styles.errorMessage}>※入力必須項目です</div>}
    </>
  );

  if (isPassword) {
    return (
      <div className={styles.passwordInput}>
        {inputText}
        <button
          className={styles.maskButton}
          type="button"
          onClick={() => setUnmasking((s) => !s)}
        >
          {unmasking ? (
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.maskIcon
              )}
            >
              visibility
            </span>
          ) : (
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.maskIcon
              )}
            >
              visibility_off
            </span>
          )}
        </button>
      </div>
    );
  }
  return inputText;
};

export default InputTextSmall;
