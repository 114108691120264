import Header from "src/components/Header";
import Footer from "src/components/Footer";
import MenuBackGround from "src/components/MenuBackGround";
import ApexMenu from "src/components/ApexMenu";
import ApexContent from "src/components/ApexContent";
import Loading from "src/components/Modal/Loading";
import FooterNews from "src/components/Footer/FooterNews";
import { useMediaQuery } from "react-responsive";
import { useApexMenu } from "src/hooks/useApexMenu";
import classnames from "classnames";
import styles from "./style.module.scss";

const Main: React.FunctionComponent = () => {
  const isHideStaticMenu = useMediaQuery({ query: "(max-width: 1365px)" });
  const isOpenMenu = useApexMenu((state) => state.isOpenMenu);
  const isSlideIn = useApexMenu((state) => state.isSlideIn);
  const closeMenu = useApexMenu((state) => state.closeMenu);
  const openMenu = useApexMenu((state) => state.openMenu);

  return (
    <div className={classnames(styles.layOutContainer)}>
      {isHideStaticMenu && !isOpenMenu ? (
        <div
          className={classnames("material-symbols-outlined", styles.menuButton)}
          onClick={() => {
            openMenu();
          }}
        >
          menu
        </div>
      ) : (
        <div
          className={classnames(
            styles.leftContents,
            isSlideIn ? styles.slidein : styles.slideout
          )}
        >
          {isHideStaticMenu && isOpenMenu && (
            <span
              className={classnames(
                "material-symbols-outlined",
                styles.closeButton
              )}
              onClick={() => {
                closeMenu();
              }}
            >
              close
            </span>
          )}
          <MenuBackGround />
          <Header />
          <ApexMenu />
          <FooterNews />
          <Footer />
        </div>
      )}
      <div className={styles.rightContents}>
        <ApexContent />
      </div>
      <Loading />
    </div>
  );
};

export default Main;
