import styles from "./style.module.scss";
import classnames from "classnames";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useApexChat } from "src/hooks/useApexChat";
import ChatTextBox from "../Base/List/ChatTextBox";
import { useEffect, useRef, useState } from "react";
import { useApexUser } from "src/hooks/useApexUser";
import PlayerKickModal from "../Modal/PlayerKickModal";
import { covertDateString, isEmpty } from "src/utils/tools/common";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import PlayerScheduleModal from "../Modal/PlayerScheduleModal";
import OwnerScheduleModal from "../Modal/OwnerScheduleModal";
import ChatMessage from "./ChatMessage";

interface Props {
  webSocket: WebSocket | undefined;
}

const Chat: React.FunctionComponent<Props> = (props) => {
  const roomInfo = useApexRoom((state) => state.roomInfo);

  const sendLobbyMessage = useApexChat((state) => state.sendLobbyMessage);
  const sendTeamMessage = useApexChat((state) => state.sendTeamMessage);
  const [lobbyChatText, setLobbyChatText] = useState<string>("");
  const [teamChatText, setTeamChatText] = useState<string>("");

  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";

  const setRoomInfo = useApexRoom((state) => state.setRoomInfo);
  const leaveApexRoom = useApexRoom((state) => state.leaveApexRoom);
  const userName = useApexUser((state) => state.userName);
  const userType = useApexUser((state) => state.userType);
  const uuid = useApexUser((state) => state.uuid);
  const teamName = useApexUser((state) => state.teamName);
  const isInTeam = useApexUser((state) => state.isInTeam);
  const stopHeartBeat = useApexUser((state) => state.stopHeartBeat);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLUListElement>(null);
  const [chatSelected, setChatSelected] = useState<string>("lobby");
  const [showKickModal, setShowKickModal] = useState<boolean>(false);
  const [scrolledBottom, setScrolledBottom] = useState<boolean>(true);
  const [isShowSchedule, setIsShowSchedule] = useState<boolean>(false);
  const lobbyChat = useApexChat((state) => state.lobbyChat);
  const setLobbyChat = useApexChat((state) => state.setLobbyChat);
  const teamChat = useApexChat((state) => state.teamChat);
  const setTeamChat = useApexChat((state) => state.setTeamChat);
  const isOwner = userType === "Owner";

  const scrollToTheBootom = () => {
    scrollBottomRef?.current?.scrollIntoView({ block: "nearest" });
  };
  const ownerName = roomInfo.ownerName;
  const otherUserArr = roomInfo.members.filter(
    (member) => member.name !== roomInfo.ownerName && member.name !== userName
  );

  const isScheduling = isEmpty(roomInfo.eventDateTime);

  const navigate = useNavigate();

  const changeText = (value: string) => {
    if (chatSelected === "lobby") {
      setLobbyChatText(value);
    } else {
      setTeamChatText(value);
    }
  };

  const sendMessage = () => {
    if (chatSelected === "lobby") {
      if (lobbyChatText.trim() === "") {
        setLobbyChatText("");
        return;
      }
      sendLobbyMessage(props.webSocket, roomID, userName, lobbyChatText, uuid);
      setLobbyChatText("");
    } else {
      if (teamChatText.trim() === "") {
        setTeamChatText("");
        return;
      }
      if (teamName && !isEmpty(teamName)) {
        sendTeamMessage(
          props.webSocket,
          roomID,
          userName,
          teamChatText,
          uuid,
          teamName
        );
        setTeamChatText("");
      }
    }
  };

  useEffect(() => {
    const wsEvent = async (event: MessageEvent<any>) => {
      const wsData = JSON.parse(event.data);
      switch (wsData.type) {
        case "sendLobbyMessage":
          if (chatSelected === "lobby" && scrollRef && scrollRef.current) {
            const isBottom =
              scrollRef?.current?.scrollTop +
                scrollRef?.current?.clientHeight >=
              scrollRef?.current?.scrollHeight;
            setScrolledBottom(isBottom);
          }
          const newLobbyArray = [...roomInfo.lobbyChat];
          newLobbyArray.push(wsData.data);
          roomInfo.lobbyChat = newLobbyArray;
          setLobbyChat(newLobbyArray);
          setRoomInfo(roomInfo);
          break;
        case "sendTeamMessage":
          if (chatSelected === "team" && scrollRef && scrollRef.current) {
            const isBottom =
              scrollRef?.current?.scrollTop +
                scrollRef?.current?.clientHeight >=
              scrollRef?.current?.scrollHeight;
            setScrolledBottom(isBottom);
          }
          setTeamChat([...teamChat, wsData.data]);
          break;
      }
    };
    props.webSocket?.addEventListener("message", wsEvent);

    return () => {
      props.webSocket?.removeEventListener("message", wsEvent);
    };
  }, [
    chatSelected,
    props.webSocket,
    roomInfo,
    setLobbyChat,
    setRoomInfo,
    setTeamChat,
    teamChat,
  ]);

  useEffect(() => {
    setLobbyChat(roomInfo.lobbyChat);
    scrollToTheBootom();
  }, [roomInfo, setLobbyChat]);

  useEffect(() => {
    if (scrolledBottom) {
      scrollToTheBootom();
      setScrolledBottom(false);
    }
  }, [lobbyChat, scrolledBottom]);

  useEffect(() => {
    scrollToTheBootom();
    setScrolledBottom(true);
  }, [chatSelected]);

  return (
    <div className={styles.chatContainer}>
      <div className={styles.roomInfo}>
        <div className={styles.title}>{roomInfo.title}</div>
        <div className={styles.eventDateTimeWrapper}>
          <span className={styles.eventDateTimeInfo}>開催日：</span>
          <span
            className={classnames({
              [styles.scheduling]: isScheduling,
              [styles.scheduled]: !isScheduling,
            })}
          >
            {isScheduling ? "調整中" : covertDateString(roomInfo.eventDateTime)}
            {isScheduling && (
              <span
                className={styles.clickSchedule}
                onClick={() => {
                  setIsShowSchedule(true);
                }}
              >
                ※参加可能な日を回答してください
              </span>
            )}
          </span>
        </div>
        <div className={styles.ownerInfoWrapper}>
          <span className={styles.ownerInfo}>オーナー：</span>
          <span className={styles.ownerName}>{roomInfo.ownerName}</span>
        </div>
      </div>
      <div className={styles.chatsArea}>
        <ul className={styles.messages} ref={scrollRef}>
          <ChatMessage
            chat={chatSelected === "lobby" ? lobbyChat : teamChat}
            ownerName={ownerName}
            currentUserName={userName}
            otherUserArr={otherUserArr}
          />
          <div ref={scrollBottomRef} />
        </ul>
        <div className={styles.inputWithButton}>
          <ChatTextBox
            text={chatSelected === "lobby" ? lobbyChatText : teamChatText}
            changeMessage={changeText}
            sendMessage={sendMessage}
            placeholder={
              chatSelected === "lobby"
                ? "全てのチームへ発言"
                : "参加チームへ発言"
            }
            disabled={!isInTeam && chatSelected === "team"}
          />
        </div>
      </div>
      <div className={styles.chatModeSwitcher}>
        <span
          className={classnames(styles.lobbyChat, {
            [styles.selected]: chatSelected === "lobby",
            [styles.unSelected]: chatSelected !== "lobby",
          })}
          onClick={() => setChatSelected("lobby")}
        >
          LOBBY
        </span>
        <span
          className={classnames(styles.teamChat, {
            [styles.selected]: chatSelected === "team",
            [styles.unSelected]: chatSelected !== "team",
          })}
          onClick={() => setChatSelected("team")}
        >
          TEAM
        </span>
      </div>
      <div className={classnames(styles.buttomButtons)}>
        <span
          className={classnames(styles.leaveRoom)}
          onClick={() => {
            leaveApexRoom(props.webSocket, roomID, uuid, teamName).then(() => {
              stopHeartBeat();
              Cookies.remove("token");
              Cookies.remove("userName");
              Cookies.remove("userType");
              navigate({
                pathname: "/customGame/" + roomInfo.type,
              });
            });
          }}
        >
          ルームから出る
        </span>
        {isOwner && (
          <span
            className={classnames(styles.kickPlayers)}
            onClick={() => {
              setShowKickModal(!showKickModal);
            }}
          >
            キックする
          </span>
        )}
      </div>
      {showKickModal && (
        <PlayerKickModal
          onClose={() => {
            setShowKickModal(false);
          }}
        />
      )}
      {isOwner ? (
        <OwnerScheduleModal
          webSocket={props.webSocket}
          roomInfo={roomInfo}
          isShow={isShowSchedule}
          onClose={() => {
            setIsShowSchedule(false);
          }}
          onClick={() => {
            setIsShowSchedule(false);
          }}
        />
      ) : (
        <PlayerScheduleModal
          roomInfo={roomInfo}
          isShow={isShowSchedule}
          onClose={() => {
            setIsShowSchedule(false);
          }}
        />
      )}
    </div>
  );
};

export default Chat;
