import { create } from "zustand";
import { API } from "aws-amplify";
import {
  AddRoomInfo,
  CreateRoomResult,
  GameMode,
  Map,
  Member,
  RoomInfo,
  ScheduleDateTime,
  UpdateRoomInfo,
} from "src/utils/types/roomTypes";
import Cookies from "js-cookie";
import { isEmpty } from "src/utils/tools/common";

interface KickoutPlayer {
  uuid: string;
  name: string;
}

export interface RoomGetResult {
  items: Array<RoomInfo>;
  lastEvaluatedKey: any;
  count: number;
}

interface SearchState {
  fromDate: number;
  toDate: number;
  gameMode: GameMode;
  maps: Array<Map>;
  ownerName: string;
}

interface ApexRoomState {
  rooms: Array<RoomInfo>;
  searchData: SearchState;
  roomInfo: RoomInfo;
  loadingMessage: string;
  searching: boolean;
  setSearchData: (searchData: SearchState) => void;
  setSearching: (searching: boolean) => void;
  setLoadingMessage: (loadingMessage: string) => void;
  setRoomInfo: (roomInfo: RoomInfo) => void;
  getRoomInfo: (roomID: string) => Promise<RoomInfo>;
  getRooms: (
    type: GameMode,
    limit?: number,
    lastEvaluatedKey?: any
  ) => Promise<RoomGetResult>;
  fetchRooms: (
    type: GameMode,
    limit?: number,
    lastEvaluatedKey?: any
  ) => Promise<RoomGetResult>;
  sameTeamMembers: Array<Member>;
  setSameTeamMembers: (sameTeamMembers: Array<Member>) => void;
  checkRoomStatus: (roomID: string) => Promise<RoomInfo>;
  joinApexRoom: (
    roomID: string,
    uuid: string,
    userName: string,
    isPlayer: boolean,
    password?: string
  ) => Promise<string>;
  joinApexTeam: (
    socket: WebSocket | undefined,
    uuid: string,
    roomID: string,
    teamName: string
  ) => Promise<void>;
  leaveApexTeam: (
    socket: WebSocket | undefined,
    uuid: string,
    roomID: string,
    teamName: string
  ) => Promise<void>;
  leaveApexRoom: (
    socket: WebSocket | undefined,
    roomID: string,
    uuid: string,
    teamName: string | null
  ) => Promise<void>;
  deleteApexRoom: (roomID: string) => Promise<void>;
  kickoutPlayers: (roomID: string, players: Array<KickoutPlayer>) => void;
  updateSchedule: (
    playerID: string,
    roomID: string,
    schedule: Array<ScheduleDateTime>
  ) => void;
  updateRoomInfo: (
    socket: WebSocket | undefined,
    roomInfo: UpdateRoomInfo
  ) => Promise<void>;
  createApexRoom: (roomInfo: AddRoomInfo) => Promise<CreateRoomResult>;
  searchApexRooms: (
    type: GameMode,
    fromDate: number,
    toDate: number,
    maps: Array<Map>,
    ownerName: string,
    limit?: number,
    lastEvaluatedKey?: any
  ) => Promise<RoomGetResult>;
  fetchSearchRooms: (
    type: GameMode,
    fromDate: number,
    toDate: number,
    maps: Array<Map>,
    ownerName: string,
    limit?: number,
    lastEvaluatedKey?: any
  ) => Promise<RoomGetResult>;
}

const checkRoomStatus = async (roomID: string): Promise<RoomInfo> => {
  const request = API.post("winnityrest", "/roomStatus", {
    body: {
      roomID,
    },
  });
  const status = await request;
  return status;
};

const joinApexRoom = async (
  roomID: string,
  uuid: string,
  userName: string,
  isPlayer: boolean,
  password?: string
): Promise<string> => {
  const request = API.post("winnityrest", "/apexRoomJoin", {
    body: {
      roomID,
      uuid,
      userName,
      isPlayer,
      password,
    },
  });
  const token = await request.then((result) => {
    if (result) {
      return result.token;
    }
  });
  return token;
};

const leaveApexRoom = async (
  socket: WebSocket | undefined,
  roomID: string,
  uuid: string,
  teamName: string | null
): Promise<void> => {
  await waitForOpenSocket(socket);
  socket?.send(
    JSON.stringify({
      action: "leaveApexRoom",
      data: {
        roomID,
        uuid,
        teamName,
      },
    })
  );
};

const deleteApexRoom = async (roomID: string): Promise<void> => {
  const token = Cookies.get("token") || "";
  return API.post("winnityrest", "/deleteApexRoom", {
    headers: {
      Authorization: token,
    },
    body: {
      roomID,
    },
  });
};

const getRoomInfo = async (roomID: string): Promise<RoomInfo | null> => {
  const token = Cookies.get("token") || "";
  if (isEmpty(token)) {
    return null;
  }

  const request = API.post("winnityrest", "/apexRoomInfo", {
    headers: {
      Authorization: token,
    },
    body: {
      roomID,
    },
  });
  return await request.then((roomInfo) => {
    return roomInfo;
  });
};

const joinApexTeam = async (
  socket: WebSocket | undefined,
  uuid: string,
  roomID: string,
  teamName: string
): Promise<void> => {
  await waitForOpenSocket(socket);
  return socket?.send(
    JSON.stringify({
      action: "joinApexTeam",
      data: {
        uuid,
        roomID,
        teamName,
      },
    })
  );
};

const leaveApexTeam = async (
  socket: WebSocket | undefined,
  uuid: string,
  roomID: string,
  teamName: string
): Promise<void> => {
  await waitForOpenSocket(socket);
  socket?.send(
    JSON.stringify({
      action: "leaveApexTeam",
      data: {
        roomID,
        uuid,
        teamName,
      },
    })
  );
};

const kickoutPlayers = async (
  roomID: string,
  players: Array<KickoutPlayer>
) => {
  const token = Cookies.get("token") || "";
  if (isEmpty(token)) {
    return;
  }
  const request = API.post("winnityrest", "/playerKickout", {
    headers: {
      Authorization: token,
    },
    body: {
      roomID,
      players,
    },
  });
  return await request.then((roomInfo) => {
    return roomInfo;
  });
};

const updateSchedule = async (
  playerID: string,
  roomID: string,
  schedule: Array<ScheduleDateTime>
) => {
  const token = Cookies.get("token") || "";
  if (isEmpty(token)) {
    return;
  }
  const request = API.post("winnityrest", "/updateSchedule", {
    headers: {
      Authorization: token,
    },
    body: {
      playerID,
      roomID,
      schedule,
    },
  });
  return await request.then((roomInfo) => {
    return roomInfo;
  });
};

const updateRoomInfo = async (
  socket: WebSocket | undefined,
  roomInfo: UpdateRoomInfo
) => {
  await waitForOpenSocket(socket);
  socket?.send(
    JSON.stringify({
      action: "updateRoomInfo",
      data: roomInfo,
    })
  );
};

const createApexRoom = async (roomInfo: AddRoomInfo) => {
  const request = API.post("winnityrest", "/createApexRoom", {
    body: roomInfo,
  });
  return await request.then((addResult) => {
    return addResult;
  });
};

const getRooms = async (
  type: GameMode,
  limit?: number,
  lastEvaluatedKey?: any
): Promise<RoomGetResult> => {
  const lastKey = lastEvaluatedKey
    ? JSON.stringify(lastEvaluatedKey)
    : undefined;
  const restOperation = API.get("winnityrest", "/apexRooms", {
    queryStringParameters: {
      type,
      limit,
      lastEvaluatedKey: lastKey,
    },
  });
  const response = await restOperation;
  return response;
};

const searchApexRooms = async (
  type: GameMode,
  fromDate: number,
  toDate: number,
  maps: Array<Map>,
  ownerName: string,
  limit?: number,
  lastEvaluatedKey?: any
): Promise<RoomGetResult> => {
  const lastKey = lastEvaluatedKey
    ? JSON.stringify(lastEvaluatedKey)
    : undefined;
  const restOperation = API.post("winnityrest", "/searchApexRoom", {
    body: {
      type,
      limit,
      fromDate,
      toDate,
      maps,
      ownerName,
      lastEvaluatedKey: lastKey,
    },
  });
  const response = await restOperation;
  return response;
};

const waitForOpenSocket = async (webSocket: WebSocket | undefined) => {
  if (webSocket) {
    return new Promise<void>((resolve) => {
      if (webSocket.readyState !== webSocket.OPEN) {
        webSocket.addEventListener("open", (_) => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
};

const useApexRoom = create<ApexRoomState>()((set, get) => ({
  roomInfo: {
    id: "",
    title: "",
    keywords: [],
    ownerName: "",
    eventDateTime: 0,
    description: "",
    limitPlayerNumber: 0,
    currentPlayerNumber: 0,
    gameSettings: {
      lobbyChatFlag: true,
      selfAssignFlag: true,
      aimAssistFlag: true,
      anonymousModeFlag: false,
      gameModeFlag: false,
    },
    apexMapID: "",
    publicFlag: true,
    scheduleDateTime: [],
    members: [],
    lobbyChat: [],
    customGameCode: "",
    map: "",
    type: "",
    blacklist: "",
  },
  searchData: {
    fromDate: 0,
    toDate: 0,
    gameMode: "",
    maps: [],
    ownerName: "",
  },
  sameTeamMembers: [],
  setSameTeamMembers: (sameTeamMembers: Array<Member>) => {
    set((state) => {
      return { sameTeamMembers };
    });
  },
  setSearchData: (searchData: SearchState) => {
    set((state) => {
      return { searchData };
    });
  },
  rooms: [],
  loadingMessage: "",
  createLoading: false,
  searching: false,
  setSearching: (searching: boolean) => {
    set((state) => {
      return { searching };
    });
  },
  setLoadingMessage: (loadingMessage: string) => {
    set((state) => {
      return { loadingMessage };
    });
  },
  setRoomInfo: (roomInfo: RoomInfo) => {
    set((state) => {
      return { roomInfo };
    });
  },
  getRoomInfo: async (roomID: string) => {
    const roomInfo = await getRoomInfo(roomID);
    if (roomInfo) {
      set((state) => {
        return {
          roomInfo,
        };
      });
      return roomInfo;
    } else {
      const initRoomInfo = get().roomInfo;
      return initRoomInfo;
    }
  },
  getRooms: async (type: GameMode, limit?: number, lastEvaluatedKey?: any) => {
    return await getRooms(type, limit, lastEvaluatedKey).then((result) => {
      set((state) => {
        return {
          rooms: result.items,
        };
      });
      return result;
    });
  },
  fetchRooms: async (
    type: GameMode,
    limit?: number,
    lastEvaluatedKey?: any
  ) => {
    return await getRooms(type, limit, lastEvaluatedKey).then((result) => {
      set((state) => {
        const newRooms = state.rooms.concat(result.items);
        return {
          rooms: newRooms,
        };
      });
      return result;
    });
  },
  checkRoomStatus: async (roomID: string) => {
    return await checkRoomStatus(roomID);
  },
  joinApexRoom: async (
    roomID: string,
    uuid: string,
    userName: string,
    isPlayer: boolean,
    password?: string
  ) => {
    return await joinApexRoom(roomID, uuid, userName, isPlayer, password);
  },
  leaveApexRoom: async (
    socket: WebSocket | undefined,
    roomID: string,
    uuid: string,
    teamName: string | null
  ) => {
    await leaveApexRoom(socket, roomID, uuid, teamName);
  },
  joinApexTeam: async (
    socket: WebSocket | undefined,
    uuid: string,
    roomID: string,
    teamName: string
  ) => {
    return await joinApexTeam(socket, uuid, roomID, teamName);
  },
  leaveApexTeam: async (
    socket: WebSocket | undefined,
    roomID: string,
    uuid: string,
    teamName: string
  ) => {
    await leaveApexTeam(socket, roomID, uuid, teamName);
  },
  deleteApexRoom: async (roomID: string) => {
    await deleteApexRoom(roomID);
  },
  kickoutPlayers: (roomID: string, players: Array<KickoutPlayer>) => {
    kickoutPlayers(roomID, players).then((info) => {
      set((state) => {
        return {
          roomInfo: info,
        };
      });
    });
  },
  updateSchedule: (
    playerID: string,
    roomID: string,
    schedule: Array<ScheduleDateTime>
  ) => {
    updateSchedule(playerID, roomID, schedule).then((info) => {
      set((state) => {
        return {
          roomInfo: info,
        };
      });
    });
  },
  updateRoomInfo: async (
    socket: WebSocket | undefined,
    roomInfo: UpdateRoomInfo
  ) => {
    await updateRoomInfo(socket, roomInfo);
  },
  createApexRoom: async (roomInfo: AddRoomInfo) => {
    const addResult = await createApexRoom(roomInfo);
    return addResult;
  },
  searchApexRooms: async (
    type: GameMode,
    fromDate: number,
    toDate: number,
    maps: Array<Map>,
    ownerName: string,
    limit?: number,
    lastEvaluatedKey?: any
  ) => {
    return await searchApexRooms(
      type,
      fromDate,
      toDate,
      maps,
      ownerName,
      limit,
      lastEvaluatedKey
    ).then((result) => {
      set((state) => {
        return {
          rooms: result.items,
        };
      });
      return result;
    });
  },
  fetchSearchRooms: async (
    type: GameMode,
    fromDate: number,
    toDate: number,
    maps: Array<Map>,
    ownerName: string,
    limit?: number,
    lastEvaluatedKey?: any
  ) => {
    return await searchApexRooms(
      type,
      fromDate,
      toDate,
      maps,
      ownerName,
      limit,
      lastEvaluatedKey
    ).then((result) => {
      set((state) => {
        const newRooms = state.rooms.concat(result.items);
        return {
          rooms: newRooms,
        };
      });
      return result;
    });
  },
}));

export { useApexRoom };
