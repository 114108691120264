import { useApexMenu } from "src/hooks/useApexMenu";
import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import AddRoomButton from "src/components/Base/Button/AddRoomButton";
import Card from "src/components/Card";
import { useApexRoom } from "src/hooks/useApexRoom";
import classnames from "classnames";
import NoRooms from "src/components/NoRooms";
import { GameMode, RoomInfo } from "src/utils/types/roomTypes";
import JoinRoomModal from "src/components/Modal/JoinRoomModal";
import CURoomModal from "src/components/Modal/CURoomModal";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import SearchModal from "src/components/Modal/SearchModal";

interface Props {
  type: GameMode;
}

const CommonContent: React.FunctionComponent<Props> = (props) => {
  const limit = 50;
  const selectedMode = useApexMenu((state) => state.selectedMode);
  const selectGameMode = useApexMenu((state) => state.selectGameMode);
  const getRooms = useApexRoom((state) => state.getRooms);
  const fetchRooms = useApexRoom((state) => state.fetchRooms);
  const fetchSearchRooms = useApexRoom((state) => state.fetchSearchRooms);
  const searchData = useApexRoom((state) => state.searchData);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowCreateRoomModal, setIsShowCreateRoomModal] =
    useState<boolean>(false);
  const [isShowSearchModal, setIsShowSearchModal] = useState<boolean>(false);
  const [isShowOwnerJoinModal, setIsShowOwnerJoinModal] =
    useState<boolean>(false);
  const [rooms, setRooms] = useState<Array<RoomInfo>>([]);
  const [isShowJoinModal, setIsShowJoinModal] = useState(false);
  const [selectedRoomID, setIsSelectedRoomID] = useState<string>("");
  const isNoRooms = rooms.length === 0;
  const searching = useApexRoom((state) => state.searching);

  useEffect(() => {
    if (selectedMode !== props.type) selectGameMode(props.type);
    setIsLoading(true);
    getRooms(props.type, limit).then((result) => {
      setLastEvaluatedKey(result.lastEvaluatedKey);
      setRooms(result.items);
      setIsLoading(false);
    });
  }, [getRooms, props.type, selectGameMode, selectedMode]);

  return (
    <div className={styles.contentContainer}>
      <div className={styles.contentMenu}>
        <AddRoomButton
          isDisable={false}
          size={"tiny"}
          onClick={() => {
            setIsShowCreateRoomModal(true);
          }}
        />
        <span
          className={classnames("material-symbols-outlined", styles.ownerJoin)}
          onClick={() => {
            setIsShowOwnerJoinModal(true);
          }}
        >
          manage_accounts
        </span>
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.searchButton
          )}
          onClick={() => {
            setIsShowSearchModal(true);
          }}
        >
          filter_alt
        </span>
      </div>
      <div className={styles.contentHeader}>
        <div className={styles.title}>{apexGameInfo[props.type]?.en}</div>
        <div className={styles.subTitle}>
          {apexGameInfo[props.type]?.jp + "のルーム"}
        </div>
      </div>
      <div
        className={styles.cardsContainer}
        ref={scrollRef}
        onScroll={() => {
          if (
            scrollRef &&
            scrollRef.current &&
            !isLoading &&
            lastEvaluatedKey
          ) {
            const isBottom =
              scrollRef?.current?.scrollTop +
                scrollRef?.current?.clientHeight +
                10 >=
              scrollRef?.current?.scrollHeight;
            if (isBottom && !isLoading) {
              setIsLoading(true);
              if (searching) {
                fetchSearchRooms(
                  props.type,
                  searchData.fromDate,
                  searchData.toDate,
                  searchData.maps,
                  searchData.ownerName,
                  limit,
                  lastEvaluatedKey
                ).then((result) => {
                  setLastEvaluatedKey(result.lastEvaluatedKey);
                  const newRooms = rooms.concat(result.items);
                  setRooms(newRooms);
                  setIsLoading(false);
                });
              } else {
                fetchRooms(props.type, limit, lastEvaluatedKey).then(
                  (result) => {
                    setLastEvaluatedKey(result.lastEvaluatedKey);
                    const newRooms = rooms.concat(result.items);
                    setRooms(newRooms);
                    setIsLoading(false);
                  }
                );
              }
            }
          }
        }}
      >
        {rooms?.map((roomInfo, key) => {
          return (
            <Card
              key={key}
              roomInfo={roomInfo}
              onClick={() => {
                setIsShowJoinModal(true);
                setIsSelectedRoomID(roomInfo.id);
              }}
            />
          );
        })}
        {isLoading && lastEvaluatedKey && (
          <div className={styles.bottom}>
            <span
              className={classnames(
                "material-symbols-outlined",
                styles.loading
              )}
            >
              autorenew
            </span>
          </div>
        )}
      </div>
      {!isLoading && isNoRooms && <NoRooms />}
      {isShowJoinModal && (
        <JoinRoomModal
          isPlayer={true}
          roomID={selectedRoomID}
          roomType={props.type}
          publicFlag={true}
          onClose={() => setIsShowJoinModal(false)}
          byURL={false}
        />
      )}
      {isShowOwnerJoinModal && (
        <JoinRoomModal
          isPlayer={false}
          roomID={selectedRoomID}
          roomType={props.type}
          publicFlag={true}
          onClose={() => setIsShowOwnerJoinModal(false)}
          byURL={false}
        />
      )}
      {isShowCreateRoomModal && (
        <CURoomModal
          webSocket={undefined}
          isCreate={true}
          initRoomType={props.type}
          onClose={() => {
            setIsShowCreateRoomModal(false);
          }}
        />
      )}
      <SearchModal
        isOpen={isShowSearchModal}
        type={props.type}
        onClick={(result) => {
          setLastEvaluatedKey(result.lastEvaluatedKey);
          setRooms(result.items);
        }}
        onClose={() => {
          setIsShowSearchModal(false);
        }}
      />
    </div>
  );
};

export default CommonContent;
