import styles from "./style.module.scss";
import { Link, useSearchParams } from "react-router-dom";
import NewsHeader from "./NewsHeader";
import MockArticles from "src/assets/json/mockArticles.json";
import arrowsRight from "src/assets/images/icon/arrowsRight.svg";
import { useEffect, useState } from "react";

interface newsType {
  id: Number;
  type: String;
  date: String;
  title: String;
  image: String;
  content: String;
}

const News: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [reverseArticle, setReverseArticle] = useState<newsType[]>([]);
  const type = searchParams.get("type");

  useEffect(() => {
    if (type) {
      setReverseArticle(
        MockArticles.filter((item) => item.type === type)
          .slice()
          .reverse()
      );
    } else {
      setReverseArticle(MockArticles.slice().reverse());
    }
  }, [type]);

  return (
    <div className={styles.newsContentContainer}>
      <NewsHeader
        menu={"このサイトの更新情報やイベント情報"}
        backButtonStr="トップページへ"
        alt="トップページに戻る"
      />
      <div className={styles.newsContent}>
        {reverseArticle.map((articles, idx) => (
          <div className={styles.articleWrapper} key={idx}>
            <Link to={`/news/?type=${articles.type}`}>
              <div className={styles.typeIcon}>
                <span>{articles.type.toUpperCase()}</span>
              </div>
            </Link>
            <div className={styles.newsDate}>{articles.date}</div>
            <Link className={styles.textLink} to={`/news/${articles.id}`}>
              <div className={styles.newsTitle}>{articles.title}</div>
            </Link>
            <Link to={`/news/${articles.id}`}>
              <div className={styles.detailButton}>
                <img src={arrowsRight} alt="記事詳細へ" />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
