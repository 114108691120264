import classnames from "classnames";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import BasicButton from "src/components/Base/Button/BasicButton";
import { CreateRoomResult } from "src/utils/types/roomTypes";
import CopyBox from "src/components/Base/List/CopyBox";
import { Tooltip } from "react-tooltip";
import share_x from "src/assets/images/2x/share_x.png";
import share_line from "src/assets/images/2x/share_line.png";

interface Props {
  createResult: CreateRoomResult;
  onClose: () => void;
}

const FinishCreateModal: React.FunctionComponent<Props> = (props) => {
  const [closeWarning, setCloseWarning] = useState(false);
  const DOMAIN = window.location.origin || "";
  // const WINNITY_URL = "https://winnity.games";
  const modalRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const roomUrl: string =
  DOMAIN +
    "/apexRoom/" +
    props.createResult.type +
    "?roomID=" +
    props.createResult.id;

  //Xの投稿のパラメータ
  //投稿内容
  const tweet_text = "[Let's Play Apex Legends With WINNITY]";
  //共有するURL又はXのURL
  const tweet_url = roomUrl;
  //ツイート内に含むユーザ名（〜より）
  const screen_name = "WINNITY_GAMES";
  //共有するハッシュタグ
  const hashtag = "ApexLegends,WINNITY,カスタムゲーム";
  //ツイート後に「おすすめユーザー」として表示
  const recommend_account = "WINNITY_GAMES";

  const guestPassword_text =
    "ユーザー用パスワード: " + props.createResult.guestPassword;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        setCloseWarning(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const handleClickInfoCopy = () => {
    if (props.createResult.publicFlag) {
      const copyText =
        "ルームURL: " +
        roomUrl +
        "\nルームID: " +
        props.createResult.id +
        "\nオーナー用パスワード: " +
        props.createResult.ownerPassword;
      navigator.clipboard.writeText(copyText);
    } else {
      const copyText =
        "ルームURL: " +
        roomUrl +
        "\nルームID: " +
        props.createResult.id +
        "\nユーザー用パスワード: " +
        props.createResult.guestPassword +
        "\nオーナー用パスワード: " +
        props.createResult.ownerPassword;
      navigator.clipboard.writeText(copyText);
    }
    setShowTooltip(true);
  };

  if (closeWarning) {
    return (
      <div className={classnames(styles.modalWrapper)}>
        <div className={classnames(styles.modalMask)} />
        <div
          className={classnames(
            styles.modalContainer,
            styles.warnModalContainer
          )}
        >
          <span
            className={classnames(
              "material-symbols-outlined",
              styles.closeButton
            )}
            onClick={() => {
              setCloseWarning(false);
            }}
          >
            close
          </span>
          <div className={styles.header}>
            <div className={styles.title}>注意</div>
            {!props.createResult?.publicFlag && (
              <div className={styles.resultSubTitle}>
                非公開ルームは一覧に表示されない為、以下の点にご注意ください！
              </div>
            )}
          </div>
          <div className={styles.warnDescription}>
            <span> ルームURLやルームパスワードはこのモーダルを閉じると</span>
            <span>再表示することができなくなります。</span>
            <span>情報のコピーが完了していることを確認してください。</span>
          </div>
          <div className={styles.resultBottom}>
            <div
              className={styles.backToResult}
              onClick={() => {
                setCloseWarning(false);
              }}
            >
              戻る
            </div>
            <BasicButton
              isDisabled={false}
              visual={"third"}
              text={"作成完了"}
              onClick={() => {
                setCloseWarning(false);
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames(styles.modalWrapper)}>
      <div className={classnames(styles.modalMask)} />
      <div
        ref={modalRef}
        className={classnames(
          styles.modalContainer,
          styles.resultModalContainer
        )}
      >
        <span
          className={classnames(
            "material-symbols-outlined",
            styles.closeButton
          )}
          onClick={() => {
            setCloseWarning(true);
          }}
        >
          close
        </span>
        <div className={styles.header}>
          <div className={styles.title}>
            {!props.createResult?.publicFlag && "非"}公開ルーム作成完了
          </div>
          <div className={styles.resultSubTitle}>
            {props.createResult?.publicFlag ? (
              <>
                <div>
                  ルームは公開となり、URLを知っている人は誰でも参加できる状態です
                </div>
                <div>ルーム情報を共有して仲間を集めましょう！</div>
              </>
            ) : (
              <>
                <div>
                  ルームは非公開となり、URLとパスワードを知っている人だけが参加できる状態です
                </div>
                <div>ルーム情報を共有して仲間を集めましょう！</div>
              </>
            )}
          </div>
        </div>
        <div className={styles.contents}>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>ルームURL</div>
            </div>
            <div className={styles.inputUrlStyle}>
              <CopyBox
                value={
                  DOMAIN +
                  "/apexRoom/" +
                  props.createResult.type +
                  "?roomID=" +
                  props.createResult.id
                }
                showOpenUrl={true}
                type={"url"}
              />
            </div>
          </div>
          <hr className={styles.separation} />
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>
                <div>ルームID</div>
                <div>(オーナー再入室用)</div>
              </div>
            </div>
            <div className={styles.inputStyle}>
              <CopyBox type={"text"} value={props.createResult.id} />
            </div>
          </div>
          <hr className={styles.separation} />
          {!props.createResult?.publicFlag && (
            <>
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>
                    <div>ルームパスワード</div>
                    <div>(ユーザー用)</div>
                  </div>
                </div>
                <div className={styles.inputStyle}>
                  <CopyBox
                    type={"password"}
                    value={props.createResult.guestPassword}
                  />
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>
                <div>ルームパスワード</div>
                <div>(オーナー用)</div>
              </div>
            </div>
            <div className={styles.inputStyle}>
              <CopyBox
                type={"password"}
                value={props.createResult.ownerPassword}
              />
            </div>
          </div>
          <hr className={styles.separation} />
        </div>
        <div className={styles.bottom}>
          <BasicButton
            isDisabled={false}
            visual={"secondary"}
            text={"一括コピー"}
            onClick={() => {
              handleClickInfoCopy();
            }}
          />
          <span
            data-tooltip-id={"roomInfoCopy-showTooltip"}
            data-tooltip-content={"コピーしました！"}
            data-tooltip-place="bottom"
          />
          <Tooltip
            id="roomInfoCopy-showTooltip"
            className={styles.roomInfoCopyToolTip}
            isOpen={showTooltip}
            afterShow={() => {
              setTimeout(() => {
                setShowTooltip(false);
              }, 1000);
            }}
            delayHide={1000}
            noArrow
          />
          <div className={styles.iconWrapper}>
            <div className={styles.icon}>
              <Link
                to={`https://twitter.com/intent/tweet?hashtags=${hashtag}&original_referer=https%3A%2F%2Fcdpn.io%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&related=${recommend_account}&text=${
                  props.createResult.publicFlag
                    ? tweet_text
                    : tweet_text + "%0A" + guestPassword_text
                }&url=${tweet_url}&via=${screen_name}`}
                target="_blank"
              >
                <img
                  className={styles.shareIcon}
                  alt={"share_x"}
                  src={share_x}
                />
              </Link>
              <Link
                to={`https://social-plugins.line.me/lineit/share?url=${roomUrl}`}
                target="_blank"
              >
                <img
                  className={styles.shareIcon}
                  alt={"share_line"}
                  src={share_line}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishCreateModal;
